
export function getAppendAfterOptions(self, entity=null, records=null) {
  if (self == null) {
    return {}
  }

  //Return an object containing all entities 
  if (entity == null) {
    return { 
      REBATE: getRebates(self)
    }
  }

  const resultList = Array.isArray(records) && records.length > 0? records.map(i => ({ value: i.name, text: i.displayName })) : []
  let list = null;
  list = entity == 'REBATE'
    ? getRebates(self)
    : entity == 'RESOURCE'
    ? getResources(self)
    : entity == 'SKILL'
    ? getSkills(self)
    : entity == 'STAGE'
    ? getStages(self)
    : entity == 'STORAGE_FOLDER'
    ? getFolders(self)
    : entity == 'STORAGE_FILE'
    ? getFiles(self)
    : entity == 'USER'
    ? getUsers(self)
    : entity == 'TAG'
    ? getTags(self)
    : entity == 'PROJECT'
    ? getProjects(self)
    : entity == 'CUSTOMER'
    ? getCustomers(self)
    : entity == 'COMPANY'
    ? getCompanies(self)
    : entity == 'LOCATION'
    ? getLocations(self)
    : entity == 'DEPARTMENT'
    ? getDepartments(self)
    : entity == 'STAFF'
    ? getStaff(self)
    : entity == 'TASK'
    ? getTasks(self)
    : entity == 'BOOKING'
    ? getBookings(self)
    : entity == 'ACTIVITY'
    ? getActivities(self)
    : entity == 'CONTACT'
    ? getContacts(self)
    : entity == 'PLAN_LINK'
    ? getPredecessors(self)
    : entity == 'RESOURCE_LINK'
    ? getLinkResources(self)
    : entity == 'SKILL_LINK'
    ? getLinkSkills(self)
    : entity == 'NOTE'
    ? getNotes(self)
    : []
  list.push(...resultList);
  list.unshift({ value: null, text: '' })
  list.sort((a, b) => {
    if (a == null && b == null) {
      return 0;
    }
    if (a == null || a.text == null) {
      return 1;
    }
    if (b == null || b.text == null) {
      return -1;
    }
    return a.text.localeCompare(b.text, undefined, { sensitivity: 'base' });
  })
  return list;
}

function getRebates(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'rebate', text: self.$t('rebate.field.rebate') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') }
  ]
}

function getResources(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'description', text: self.$t('field.description') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'payAmount', text: self.$t('resource.field.cost') },
    { value: 'payCurrency', text: self.$t('resource.field.currency') },
    { value: 'payFrequency', text: self.$t('resource.field.frequency') },
    { value: 'resourceQuota', text: self.$t('resource.field.resourceQuota') },
    { value: 'files', text: self.$t('project.field.files') }
  ]
}

function getSkills(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') }
  ]
}

function getStages(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'description', text: self.$t('field.description') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') }
  ]
}

function getFolders(self) {
  return [
    { value: 'parentFolder', text: self.$t('folder.field.parent_folder') },
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
  ]
}

function getFiles(self) {
  return [
    { value: 'parentFolder', text: self.$t('file.field.parent_folder') },
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'description', text: self.$t('field.description') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'file', text: self.$t('file.field.file') }
  ]
}
function getUsers(self) {
  return [
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'firstName', text: self.$t('user.field.firstName') },
    { value: 'lastName', text: self.$t('user.field.lastName') },
    { value: 'email', text: self.$t('user.field.email') },
    { value: 'nickName', text: self.$t('user.field.nickName') },
    { value: 'mobile', text: self.$t('user.field.mobile') },
    { value: 'accessPolicy', text: self.$t('user.field.accessPolicy') },
    { value: 'ldapLogin', text: self.$t('user.ldapLoginLabel')}
  ]
}

function getTags(self) {
  return [
    { value: 'name', text: self.$t('field.name') }
  ]
}

function getProjects(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'description', text: self.$t('field.description') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'companies', text: self.$t('project.field.companies') },
    { value: 'locations', text: self.$t('project.field.location') },
    { value: 'customers', text: self.$t('project.field.customers') },
    { value: 'priority', text: self.$t('project.field.priority') },
    { value: 'status', text: self.$t('project.field.status') },
    { value: 'scheduleStart', text: self.$t('project.field.scheduleStart') },
    { value: 'scheduleFinish', text: self.$t('project.field.scheduleFinish') },
    { value: 'fixedDuration', text: self.$t('project.field.fixedDuration') },
    { value: 'estimatedDuration', text: self.$t('project.field.estimatedDuration') },
    { value: 'actualDuration', text: self.$t('project.field.actualDuration') },
    { value: 'scheduleMode', text: self.$t('project.field.scheduleMode') },
    { value: 'autoScheduling', text: self.$t('project.field.autoScheduling') },
    { value: 'fixedCost', text: self.$t('project.field.fixedCost') },
    { value: 'actualCost', text: self.$t('project.field.actualCost') },
    { value: 'estimatedCost', text: self.$t('project.field.estimatedCost') },
    { value: 'currencyCode', text: self.$t('project.field.currencyCode') },
    { value: 'rebates', text: self.$t('project.field.rebates') },
    { value: 'taskStages', text: self.$t('project.field.taskStages') },
    { value: 'files', text: self.$t('project.field.files') }
  ]
}

function getCustomers(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'description', text: self.$t('field.description') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'nickName', text: self.$t('customer.field.nickName') },
    { value: 'location', text: self.$t('customer.field.location') },
    { value: 'websites', text: self.$t('customer.field.websites') },
    { value: 'socials', text: self.$t('customer.field.socials') },
    { value: 'contacts', text: self.$t('customer.field.contacts') },
  ]
}

function getCompanies(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'type', text: self.$t('company.field.type') },
    { value: 'location', text: self.$t('company.field.location') },
  ]
}

function getLocations(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'street', text: self.$t('location.field.street') },
    { value: 'town', text: self.$t('location.field.town') },
    { value: 'region', text: self.$t('location.field.region') },
    { value: 'postcode', text: self.$t('location.field.postcode') },
    { value: 'country', text: self.$t('location.field.country') },
    { value: 'rebates', text: self.$t('location.field.rebates') },
    { value: 'calendar', text: self.$t('location.field.calendar') },
  ]
}

function getDepartments(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') }
  ]
}

function getStaff(self) {
  return [
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'firstName', text: self.$t('staff.field.firstName') },
    { value: 'lastName', text: self.$t('staff.field.lastName') },
    { value: 'email', text: self.$t('staff.field.email') },
    { value: 'companies', text: self.$t('staff.field.company') },
    { value: 'departments', text: self.$t('staff.field.departments') },
    { value: 'locations', text: self.$t('staff.field.locations') },
    { value: 'phones', text: self.$t('staff.field.phones') },
    { value: 'position', text: self.$t('staff.field.position') },
    { value: 'websites', text: self.$t('staff.field.websites')},
    { value: 'socials', text: self.$t('staff.field.socials')},
    { value: 'type', text: self.$t('staff.field.type')},
    { value: 'startDate', text: self.$t('staff.field.startDate')},
    { value: 'endDate', text: self.$t('staff.field.endDate')},
    { value: 'skills', text: self.$t('staff.field.skills') },
    { value: 'resources', text: self.$t('staff.field.resources') },
    { value: 'payAmount', text: self.$t('staff.field.payAmount') },
    { value: 'payCurrency', text: self.$t('staff.field.payCurrency') },
    { value: 'payFrequency', text: self.$t('staff.field.payFrequency') },
    { value: 'files', text: self.$t('staff.field.files') },
    { value: 'calendar', text: self.$t('staff.field.calendar') },
    { value: 'name', text: self.$t('staff.field.name')},
    { value: 'resourceQuota', text: self.$t('staff.field.resourceQuota')}
  ]
}

function getTasks(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'description', text: self.$t('field.description') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'tags', text: self.$t('field.tag') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'projects', text: self.$t('task.field.project') },
    { value: 'parentTask', text: self.$t('task.field.parent') },
    { value: 'type', text: self.$t('task.field.taskType') },
    { value: 'priority', text: self.$t('task.field.priority') },
    { value: 'complexity', text: self.$t('task.field.complexity') },
    { value: 'startTime', text: self.$t('task.field.startTime') },
    { value: 'closeTime', text: self.$t('task.field.closeTime') },
    { value: 'fixedDuration', text: self.$t('task.field.fixedDuration') },
    { value: 'estimatedDuration', text: self.$t('task.field.estimatedDuration') },
    { value: 'actualDuration', text: self.$t('task.field.actualDuration') },
    { value: 'etc', text: self.$t('task.field.estimatedTimeToComplete') },
    { value: 'progress', text: self.$t('task.field.progress') },
    { value: 'autoScheduling', text: self.$t('task.field.autoScheduling') },
    { value: 'stage', text: self.$t('task.field.stage') },
    { value: 'constraint', text: self.$t('task.field.constraint') },
    { value: 'skills', text: self.$t('task.field.skills') },
    { value: 'staffs', text: self.$t('task.field.staffs') },
    { value: 'resources', text: self.$t('task.field.resources') },
    { value: 'fixedCost', text: self.$t('task.field.fixedCost') },
    { value: 'actualCost', text: self.$t('task.field.actualCost') },
    { value: 'estimatedCost', text: self.$t('task.field.estimatedCost') },
    { value: 'currencyCode', text: self.$t('task.field.currencyCode') },
    { value: 'rebates', text: self.$t('task.field.rebates') },
    { value: 'files', text: self.$t('task.field.files') },
    { value: 'predecessors', text: self.$t('task_predecessor.title_predecessors') },
    { value: 'image', text: self.$t('task.field.image') }
  ]
}

function getBookings(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'description', text: self.$t('field.description') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'projects', text: self.$t('task.field.project') },
    { value: 'startTime', text: self.$t('task.field.startTime') },
    { value: 'closeTime', text: self.$t('task.field.closeTime') },
    { value: 'fixedDuration', text: self.$t('task.field.fixedDuration') },
    { value: 'estimatedDuration', text: self.$t('task.field.estimatedDuration') },
    { value: 'stage', text: self.$t('task.field.stage') },
    { value: 'staffs', text: self.$t('task.field.staffs') },
    { value: 'fixedCost', text: self.$t('task.field.fixedCost') },
    { value: 'estimatedCost', text: self.$t('task.field.estimatedCost') },
    // { value: 'actualCost', text: self.$t('task.field.actualCost') },
    { value: 'currencyCode', text: self.$t('task.field.currencyCode') },
    { value: 'rebates', text: self.$t('task.field.rebates') },
  ]
}

function getActivities(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'description', text: self.$t('field.description') },
    { value: 'color', text: self.$t('field.color') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'projects', text: self.$t('task.field.project') },
    { value: 'startTime', text: self.$t('task.field.startTime') },
    { value: 'closeTime', text: self.$t('task.field.closeTime') },
    { value: 'fixedDuration', text: self.$t('task.field.fixedDuration') },
    { value: 'estimatedDuration', text: self.$t('task.field.estimatedDuration') },
    { value: 'actualDuration', text: self.$t('task.field.actualDuration') },
    { value: 'stage', text: self.$t('task.field.stage') },
    { value: 'staffs', text: self.$t('task.field.staffs') },
    { value: 'fixedCost', text: self.$t('task.field.fixedCost') },
    { value: 'estimatedCost', text: self.$t('task.field.estimatedCost') },
    { value: 'actualCost', text: self.$t('task.field.actualCost') },
    { value: 'currencyCode', text: self.$t('task.field.currencyCode') },
    { value: 'rebates', text: self.$t('task.field.rebates') },
  ]
}

function getContacts(self) {
  return [
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'notes', text: self.$t('field.note') },
    { value: 'firstName', text: self.$t('contact.field.firstName') },
    { value: 'lastName', text: self.$t('contact.field.lastName') },
    { value: 'company', text: self.$t('contact.field.company') },
    { value: 'locations', text: self.$t('contact.field.locations') },
    { value: 'phones', text: self.$t('staff.field.phones') },
    { value: 'emails', text: self.$t('contact.field.emails') },
    { value: 'position', text: self.$t('staff.field.position') },
    { value: 'websites', text: self.$t('staff.field.websites')},
    { value: 'socials', text: self.$t('staff.field.socials')},
  ]
}

function getPredecessors(self) {
  return [
    { value: 'name', text: self.$t('field.name') },
    { value: 'type', text: self.$t('task_predecessor.field.type') },
    { value: 'lag', text: self.$t('task_predecessor.field.lag') },
  ]
}

function getLinkResources(self) {
  return [
    { value: 'name', text: self.$t('resource.field.resource') },
    { value: 'unit', text: self.$t('resource.field.unit') },
    { value: 'utilization', text: self.$t('resource.field.utilization') },
  ]
}

function getLinkSkills(self) {
  return [
    { value: 'name', text: self.$t('skill.field.skill') },
    { value: 'level', text: self.$t('skill.field.level') },
  ]
}

function getNotes(self) {
  return [
    { value: 'identifier', text: self.$t('field.identifier_full') },
    { value: 'note', text: self.$t('note') },
  ]
}

export function getAppendAfterTopDownRelationship(records) {
  const map = {}
  for (const record of records) {
    const key = record.append_after != null? record.append_after : null;
    if (Object.hasOwn(map, key)) {
      map[key].push(record.name);
    } else {
      map[key] = [record.name];
    }
  }

  const keys = Object.keys(map);
  for (const k of keys) {
    const currentList = map[k];
    for (const v of currentList) {
      if (keys.includes(v) && Object.hasOwn(map, v) && map[v].length > 0) {
        map[k].push(...map[v])
      }
    }
  }

  return map;  
}

export function getAppendAfterObjectWithTopDownRelationship(customFields, allowViewFunc=null) {
  const map = {}
  for (const record of customFields) {
    const appendAfter = record.profile?.append_after != null? record.profile.append_after : 'default';
    if (Object.hasOwn(map, appendAfter)) {
      map[appendAfter].push(record.name);
    } else {
      map[appendAfter] = [record.name];
    }
  }
  
  const keys = Object.keys(map);
  for (const k of keys) {
    const currentList = JSON.parse(JSON.stringify(map[k]));
    //Index is used to trace the starting point of injecting values. This helps to keep the append after order.
    let index = 0;
    for (const v of currentList) {
      index++;
      if (keys.includes(v) && map[v].length > 0) {
        map[k].splice(index, 0, ...map[v])
        index+=map[v].length;
      }
    }
  }
  
  for (const k of keys) {
    const nameList = map[k];
    const list = []
    for (const name of nameList) {
      const found = customFields.find(i => i.name == name);
      if (found) {
        list.push(found);
      }
    }
    map[k] = list;
  }
  
  //Filtered by view permission
  if (allowViewFunc != null && typeof allowViewFunc === 'function') {
    const mKeys = Object.keys(map);
    for (const k of mKeys) {
      const filteredFields = []
      for (const f of map[k]) {
        if (allowViewFunc(f.name)) {
          filteredFields.push(f);
        }
      }
      if (filteredFields.length < map[k].length) {
        map[k].splice(0, map[k].length, ...filteredFields);
      }
    }
  }
  return map;  
}